import React, { useEffect, useState, useRef } from 'react';
import { images } from '../../constants';

import './MobileImgServicesSlider.scss';

const MobileImgServicesSlider = () => {
  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#0088FE'];
  const delay = 2500;

  const [imgIndex, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === colors.length - 1 ? 0 : prevIndex + 1)),
      delay,
    );

    return () => {
      resetTimeout();
    };
  }, [imgIndex]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-imgIndex * 100}%, 0, 0)` }}
      >
        <div
          className="slide"
          key={1}
        >
          <div id="slide-service-item">
            <div className="app__services-bg-container" />
            <img src={images.servicesValuation} className="app__services-list-img" />
            <span>Tasación de maquinaria agrícola</span>
          </div>
        </div>
        <div
          className="slide"
          key={2}
        >
          <div id="slide-service-item">
            <div className="app__services-bg-container" />
            <img src={images.servicesOldTrucks} className="app__services-list-img" />
            <span>Maquinaria agrícola usada seleccionada</span>
          </div>
        </div>
        <div
          className="slide"
          key={3}
        >
          <div id="slide-service-item">
            <div className="app__services-bg-container" />
            <img src={images.servicesNewTrucks} className="app__services-list-img" />
            <span>Maquinaria agrícola nueva</span>
          </div>
        </div>
        <div
          className="slide"
          key={4}
        >
          <div id="slide-service-item">
            <div className="app__services-bg-container" />
            <img src={images.servicesTools} className="app__services-list-img" />
            <span>Repuestos para el agro en general</span>
          </div>
        </div>
      </div>

      <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${imgIndex === idx ? ' active' : ''}`}
            onClick={() => {
              setIndex(idx);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileImgServicesSlider;
