/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Admin.scss';
import axios from 'axios';
import Papa from 'papaparse';
import { images } from '../../constants';

const Admin = () => {
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [currentList, setCurrentList] = React.useState(localStorage.getItem('SelectedOption') || 'CATEGORIAS');
  const [inputCatalogSearch, setInputCatalogFilter] = React.useState('');
  const navigate = useNavigate();
  const { innerWidth } = window;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const productsHeaders = [
    'Nombre',
    'Descripcion',
    'Nuevo/Usado',
    '', // Editar
    '', // Eliminar
  ];

  const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

  const handleRemove = async (e) => {
    e.preventDefault();
    const user = getCurrentUser();
    try {
      switch (currentList) {
        case 'CATEGORIAS':
          await axiosInstance.delete(`categories/${e.target.id}`, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          break;
        case 'SUB-CATEGORIAS':
          await axiosInstance.delete(`subCategories/${e.target.id}`, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          break;
        case 'MARCAS':
          await axiosInstance.delete(`brands/${e.target.id}`, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          break;
        case 'PRODUCTOS':
          await axiosInstance.delete(`products/${e.target.id}`, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          break;
        default:
          break;
      }
      window.location.reload('/admin');
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchCategories = async (e) => {
    e.preventDefault();
    let { data: fetchedCategories } = await axiosInstance.get(
      'categories',
    );
    fetchedCategories = fetchedCategories.map((fetchedCategory) => ({
      ...fetchedCategory,
      display: 'flex',
    }));
    setCategories(fetchedCategories);
    setCurrentList('CATEGORIAS');
    localStorage.setItem('SelectedOption', 'CATEGORIAS');
  };

  const handleFetchSubCategories = async (e) => {
    e.preventDefault();
    let { data: fetchedSubCategories } = await axiosInstance.get(
      'subCategories',
    );
    fetchedSubCategories = fetchedSubCategories.map((fetchedSubCategory) => ({
      ...fetchedSubCategory,
      display: 'flex',
    }));
    setSubCategories(fetchedSubCategories);
    setCurrentList('SUB-CATEGORIAS');
    localStorage.setItem('SelectedOption', 'SUB-CATEGORIAS');
  };

  const handleFetchBrands = async (e) => {
    e.preventDefault();
    let { data: fetchedBrands } = await axiosInstance.get(
      'brands',
    );
    fetchedBrands = fetchedBrands.map((fetchedBrand) => ({
      ...fetchedBrand,
      display: 'flex',
    }));
    setBrands(fetchedBrands);
    setCurrentList('MARCAS');
    localStorage.setItem('SelectedOption', 'MARCAS');
  };

  const handleFetchProducts = async (e) => {
    e.preventDefault();
    let { data: fetchedProducts } = await axiosInstance.get('products');
    if (fetchedProducts.result && fetchedProducts.result.length > 0) {
      fetchedProducts = fetchedProducts.result.map((fetchedProduct) => ({
        ...fetchedProduct,
        display: 'flex',
      }));
    } else {
      fetchedProducts = fetchedProducts.result;
    }

    setProducts(fetchedProducts);
    setCurrentList('PRODUCTOS');
    localStorage.setItem('SelectedOption', 'PRODUCTOS');
  };

  const handleFetchMessages = async (e) => {
    e.preventDefault();
    const user = getCurrentUser();

    let { data: fetchedMessages } = await axiosInstance.get(
      'messages',
      {
        headers: {
          'x-auth-token': user.accessToken,
        },
      },
    );
    fetchedMessages = fetchedMessages.map((fetchedMessage) => ({
      ...fetchedMessage,
      display: 'flex',
    }));
    setMessages(fetchedMessages);
    setCurrentList('CORREOS');
    localStorage.setItem('SelectedOption', 'CORREOS');
  };

  const handleEdit = (e) => {
    e.preventDefault();
    switch (currentList) {
      case 'CATEGORIAS':
        const dataCategory = categories.find((category) => category.id === Number(e.target.id));
        const propsCategory = { state: { name: dataCategory.name, description: dataCategory.description, title: 'Editar categoria', formAction: 'UPDATE', id: dataCategory.id, submitBtnText: 'Actualizar' } };
        navigate(`/admin/editarCategoria/${e.target.id}`, propsCategory);
        break;
      case 'SUB-CATEGORIAS':
        const dataSubCategory = subCategories.find((subCategory) => subCategory.id === Number(e.target.id));
        const propsSubCategory = { state: { name: dataSubCategory.name, description: dataSubCategory.description, title: 'Editar sub-categoria', formAction: 'UPDATE', id: dataSubCategory.id, submitBtnText: 'Actualizar', categorySelected: dataSubCategory.categoryId } };
        navigate(`/admin/editarSubCategoria/${e.target.id}`, propsSubCategory);
        break;
      case 'MARCAS':
        const dataBrand = brands.find((brand) => brand.id === Number(e.target.id));
        const propsBrand = { state: { name: dataBrand.name, description: dataBrand.description, title: 'Editar marca', formAction: 'UPDATE', id: dataBrand.id, submitBtnText: 'Actualizar' } };
        navigate(`/admin/editarMarca/${e.target.id}`, propsBrand);
        break;
      case 'PRODUCTOS':
        const dataProduct = products.find((product) => product.id === Number(e.target.id));
        const propsProduct = {
          state: {
            name: dataProduct.name,
            description: dataProduct.description,
            title: 'Editar producto',
            formAction: 'UPDATE',
            id: dataProduct.id,
            submitBtnText: 'Actualizar',
            category: dataProduct.Category ? dataProduct.Category.id : '',
            subCategory: dataProduct.SubCategory ? dataProduct.SubCategory.id : '',
            brand: dataProduct.Brand ? dataProduct.Brand.id : '',
            isNew: dataProduct.isNew ? 'new' : 'used',
          },
        };
        navigate(`/admin/editarProducto/${e.target.id}`, propsProduct);
        break;
      default:
        break;
    }
  };

  const renderCatalogs = () => {
    switch (currentList) {
      case 'CATEGORIAS':
        return (categories && categories.map((category) => (
          <li className="table-row" key={category.id} style={{ display: `${category.display}`, alignItems: 'start' }}>
            <div className="col col-1" data-label="name">{category.name}</div>
            <div className="col col-2" data-label="description">{category.description ? category.description.slice(0, 100) : ''}</div>
            <div className="col col-3">&nbsp;</div>
            <div className="col col-4" data-label="Edit">
              <button type="button" id={category.id} onClick={(e) => handleEdit(e)} className="btn-edit">Editar</button>
            </div>
            <div className="col col-5" data-label="Remove">
              <button type="button" id={category.id} onClick={handleRemove} className="btn-remove">Eliminar</button>
            </div>
          </li>
        ))) || [];
      case 'SUB-CATEGORIAS':
        return (subCategories && subCategories.map((subCategory) => (
          <li className="table-row" key={subCategory.id} style={{ display: `${subCategory.display}`, alignItems: 'start' }}>
            <div className="col col-1" data-label="name">{subCategory.name}</div>
            <div className="col col-2" data-label="description">{subCategory.description ? subCategory.description.slice(0, 80) : ''}</div>
            <div className="col col-3" style={{ width: '25%' }}>{subCategory.Category && subCategory.Category.name ? subCategory.Category.name.slice(0, 30) : ''}</div>
            <div className="col col-4" data-label="Edit">
              <button type="button" id={subCategory.id} onClick={(e) => handleEdit(e)} className="btn-edit">Editar</button>
            </div>
            <div className="col col-5" data-label="Remove">
              <button type="button" id={subCategory.id} onClick={handleRemove} className="btn-remove">Eliminar</button>
            </div>
          </li>
        ))) || [];
      case 'MARCAS':
        return (brands && brands.map((brand) => (
          <li className="table-row" key={brand.id} style={{ display: `${brand.display}`, alignItems: 'start' }}>
            <div className="col col-1" data-label="name">{brand.name}</div>
            <div className="col col-2" data-label="description">{brand.description ? brand.description.slice(0, 100) : ''}</div>
            <div className="col col-3">&nbsp;</div>
            <div className="col col-4" data-label="Edit">
              <button type="button" id={brand.id} onClick={(e) => handleEdit(e)} className="btn-edit">Editar</button>
            </div>
            <div className="col col-5" data-label="Remove">
              <button type="button" id={brand.id} onClick={handleRemove} className="btn-remove">Eliminar</button>
            </div>
          </li>
        ))) || [];
      case 'PRODUCTOS':
        return (products && products.map((product) => (
          <li className="table-row" key={product.id} style={{ display: `${product.display}`, alignItems: 'start' }}>
            <div className="col col-1" data-label="name">{product.name}</div>
            <div className="col col-2" data-label="description-products">{product.description ? product.description.slice(0, 100) : ''}</div>
            <div className="col col-3" data-label="new-used">
              {
                product.isNew ? 'Nuevo' : 'Usado'
              }
            </div>
            <div className="col col-4" data-label="Edit">
              <button type="button" id={product.id} onClick={(e) => handleEdit(e)} className="btn-edit">Editar</button>
            </div>
            <div className="col col-5" data-label="Remove">
              <button type="button" id={product.id} onClick={handleRemove} className="btn-remove">Eliminar</button>
            </div>
          </li>
        ))) || [];
      case 'CORREOS':
        return (messages && messages.map((message) => (
          <li className="table-row" key={message.id} style={{ display: `${message.display}`, alignItems: 'start' }}>
            <div className="col col-1" data-label="name">{message.name}</div>
            <div className="col col-2" data-label="mail">{message.email ? message.email.slice(0, 100) : ''}</div>
            <div className="col col-3" data-label="phone" style={{ width: '20%' }}>{message.phone}</div>
            <div className="col col-4" data-label="" />
            <div className="col col-5" data-label="" />
          </li>
        ))) || [];
      default:
        return null;
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem('user');
    window.location.reload('/admin/login');
  };

  const handleFilterInCatalog = (e) => {
    e.preventDefault();
    setInputCatalogFilter(e.target.value);
  };

  useEffect(() => {
    switch (currentList) {
      case 'CATEGORIAS':
        const newCategories = categories.map((category) => {
          if (category.name.toLowerCase().includes(inputCatalogSearch.toLowerCase()) || category.description.toLowerCase().includes(inputCatalogSearch.toLowerCase())) {
            return { ...category, display: 'flex' };
          }
          return { ...category, display: 'none' };
        });
        setCategories(newCategories);
        break;
      case 'SUB-CATEGORIAS':
        const newSubCategories = subCategories.map((subCategory) => {
          if (subCategory.name.toLowerCase().includes(inputCatalogSearch.toLowerCase()) || subCategory.description.toLowerCase().includes(inputCatalogSearch.toLowerCase())) {
            return { ...subCategory, display: 'flex' };
          }
          return { ...subCategory, display: 'none' };
        });
        setSubCategories(newSubCategories);
        break;
      case 'MARCAS':
        const newBrands = brands.map((brand) => {
          if (brand.name.toLowerCase().includes(inputCatalogSearch.toLowerCase()) || brand.description.toLowerCase().includes(inputCatalogSearch.toLowerCase())) {
            return { ...brand, display: 'flex' };
          }
          return { ...brand, display: 'none' };
        });
        setBrands(newBrands);
        break;
      case 'PRODUCTOS':
        const newProducts = products.map((product) => {
          if (product.name.toLowerCase().includes(inputCatalogSearch.toLowerCase()) || product.description.toLowerCase().includes(inputCatalogSearch.toLowerCase())) {
            return { ...product, display: 'flex' };
          }
          return { ...product, display: 'none' };
        });

        setProducts(newProducts);
        break;
      case 'CORREOS':
        const newMails = messages.map((message) => {
          if (message.name.toLowerCase().includes(inputCatalogSearch.toLowerCase()) || message.email.toLowerCase().includes(inputCatalogSearch.toLowerCase())) {
            return { ...message, display: 'flex' };
          }
          return { ...message, display: 'none' };
        });
        setMessages(newMails);
        break;
      default:
        break;
    }
  }, [inputCatalogSearch]);

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
    const user = getCurrentUser();
    if (!user || !user.accessToken) {
      navigate('/admin/login');
    }
    const innerFunction = async () => {
      switch (currentList) {
        case 'CATEGORIAS':
          const { data: fetchedCategories } = await axiosInstance.get(
            'categories',
          );
          setCategories(fetchedCategories);
          break;
        case 'SUB-CATEGORIAS':
          const { data: fetchedSubCategories } = await axiosInstance.get(
            'subCategories',
          );
          setSubCategories(fetchedSubCategories);
          break;
        case 'MARCAS':
          const { data: fetchedBrands } = await axiosInstance.get(
            'brands',
          );
          setBrands(fetchedBrands);
          break;
        case 'PRODUCTOS':
          const { data: fetchedProducts } = await axiosInstance.get(
            'products',
          );
          setProducts(fetchedProducts.result);
          break;
        case 'CORREOS':
          const { data: fetchedMessages } = await axiosInstance.get(
            'messages',
            {
              headers: {
                'x-auth-token': user.accessToken,
              },
            },
          );
          setMessages(fetchedMessages);
          break;
        default:
          const { data: fetchedCategoriesDef } = await axiosInstance.get(
            'categories',
          );
          setCategories(fetchedCategoriesDef);
          break;
      }
    };
    innerFunction();
    renderCatalogs();
  }, []);

  const handleAddCatalog = (e) => {
    e.preventDefault();
    switch (currentList) {
      case 'CATEGORIAS':
        navigate('/admin/agregarCategoria');
        break;
      case 'SUB-CATEGORIAS':
        navigate('/admin/agregarSubCategoria');
        break;
      case 'MARCAS':
        navigate('/admin/agregarMarca');
        break;
      case 'PRODUCTOS':
        navigate('/admin/agregarProducto');
        break;
      default:
        break;
    }
  };

  const handleDownloadMails = () => {
    const data = messages && messages.map((message) => {
      const { name: nombre, email, phone: telefono } = message;
      return { nombre, email, telefono };
    });
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'Correos-consultas-web.csv');
    link.click();
  };

  return (
    <>
      <div className="app__admin-header">
        <div className="app__admin-header-logo">
          <a href="/admin">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__admin-header-sm-logo">
          <a href="/admin">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
        <button type="button" onClick={handleLogOut} className="logout-btn">Log out</button>
      </div>
      <div className="app__admin-container">
        <div className="app__admin-categories-list">
          <div className="app__admin-categories-mobile" />
          <div className="app__admin-categories">
            <span className="app__admin-span">Catálogos</span>
            <div className="app__admin-category">
              <ul>
                <li
                  className="app__admin-filter-li"
                  key="category"
                  onClick={handleFetchCategories}
                  style={currentList === 'CATEGORIAS' ? { backgroundColor: 'var(--nav-bar-color)', color: 'white' } : {}}
                >
                  Categorías
                </li>
                <li
                  className="app__admin-filter-li"
                  key="subCategory"
                  onClick={handleFetchSubCategories}
                  style={currentList === 'SUB-CATEGORIAS' ? { backgroundColor: 'var(--nav-bar-color)', color: 'white' } : {}}
                >
                  Sub categorías
                </li>
                <li
                  className="app__admin-filter-li"
                  key="brand"
                  onClick={handleFetchBrands}
                  style={currentList === 'MARCAS' ? { backgroundColor: 'var(--nav-bar-color)', color: 'white' } : {}}
                >
                  Marcas
                </li>
                <li
                  className="app__admin-filter-li"
                  key="product"
                  onClick={handleFetchProducts}
                  style={currentList === 'PRODUCTOS' ? { backgroundColor: 'var(--nav-bar-color)', color: 'white' } : {}}
                >
                  Productos
                </li>
                <li
                  className="app__admin-filter-li"
                  key="message"
                  onClick={handleFetchMessages}
                  style={currentList === 'CORREOS' ? { backgroundColor: 'var(--nav-bar-color)', color: 'white' } : {}}
                >
                  Correos
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="app__admin-categories-data">
          <div className="container">
            <div id="app__admin-search-catalog-container">
              <input placeholder={`Buscar ${currentList.toLowerCase().slice(0, -1)}`} value={inputCatalogSearch} onChange={(e) => handleFilterInCatalog(e)} type="text" />
            </div>
            <div className="header">
              <h2>{currentList}</h2>
              {
                currentList !== 'CORREOS' ? (
                  <button type="button" onClick={(e) => handleAddCatalog(e)}>Agregar {currentList.toLowerCase().slice(0, -1)}</button>
                )
                  : (<button type="button" onClick={(e) => handleDownloadMails(e)}>Descargar correos</button>)

              }
            </div>
            <ul className="responsive-table">
              <li className="table-header">
                { currentList === 'PRODUCTOS'
                  ? productsHeaders.map((header, index) => (
                    <div className={`col col-${index + 1}`}>{header}</div>
                  ))
                  : (
                    <>
                      <div className="col col-1">{currentList === 'CORREOS' ? 'Nombre cliente' : 'Nombre'}</div>
                      <div className="col col-2">{currentList === 'CORREOS' ? 'Email' : 'Descripción'}</div>
                      <div className="col col-3" style={currentList === 'SUB-CATEGORIAS' ? { width: '25%' } : { width: '20%' }}>{currentList === 'CORREOS' ? 'Teléfono' : (currentList === 'SUB-CATEGORIAS' ? 'Categoría' : '')}</div>
                      <div className="col col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>{currentList === 'CORREOS' ? ' ' : 'Acciones'}</div>
                      <div className="col col-5" />
                    </>
                  )}
              </li>
              {renderCatalogs()}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
