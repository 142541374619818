/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Slider from 'infinite-react-carousel';
import { images } from '../../constants';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Customers.scss';

const Customers = () => (
  <div className="app__customers">
    <div className="app__customers-title">
      <h1 className="head-text">Nuestros clientes</h1>
      <h4 className="head-text">Quienes hacen esto posible</h4>
    </div>
    <div className="app__customers-customers">
      { window.innerWidth > 768
        ? (
          <Slider autoplay slidesToShow={2} arrows={false}>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer1} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer2} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer3} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer4} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer5} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer6} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer7} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer8} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer9} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer10} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer11} />
            </div>
          </Slider>
        ) : (
          <Slider autoplay slidesToShow={1} arrows={false}>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer1} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer2} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer3} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer4} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer5} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer6} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer7} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer8} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer9} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer10} />
            </div>
            <div className="app__customers-item-mobile-customer">
              <img src={images.customer11} />
            </div>
          </Slider>
        )}
    </div>
  </div>
);

export default AppWrap(
  MotionWrap(Customers, 'app__customers'),
  'Clientes',
  'app__customers-main',
);
