/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import Slider from 'infinite-react-carousel';
import { images } from '../../constants';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Brands.scss';

const Brands = () => (
  <div className="app__brands">
    <div className="app__brands-title">
      <h1 className="head-text">Nuestras marcas</h1>
      <h4 className="head-text">Aliados con años de respaldo</h4>
    </div>
    <div className="app__brands-brands">
      { window.innerWidth > 768
        ? (
          <Slider autoplay slidesToShow={6} arrows={false}>
            <div className="app__brands-item-mobile">
              <img src={images.masseyFerguson} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.apache} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.tatuMarchesan} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.shell} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.dolbi} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.sitrex} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.baldan} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.krone} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.caiman} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.aguirre} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.bertini} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.cum} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.inroda} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.jhartwich} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.ozdoken} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.richiger} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.vicon} />
            </div>
          </Slider>
        ) : (
          <Slider autoplay slidesToShow={1} arrows={false}>
            <div className="app__brands-item-mobile">
              <img src={images.masseyFerguson} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.apache} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.tatuMarchesan} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.shell} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.dolbi} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.sitrex} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.baldan} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.krone} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.caiman} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.aguirre} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.bertini} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.cum} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.inroda} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.jhartwich} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.ozdoken} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.richiger} />
            </div>
            <div className="app__brands-item-mobile">
              <img src={images.vicon} />
            </div>
          </Slider>
        )}
    </div>
  </div>
);

export default AppWrap(
  MotionWrap(Brands, 'app__brands'),
  'Marcas',
  'app__brands-main',
);
