/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

import './FormForgotPassword.scss';
import { images } from '../../../constants';

const FormForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [forgotAuthError, setFormForgotPasswordAuthError] = useState(false);
  const [forgotServerError, setFormForgotPasswordServerError] = useState(false);
  const navigate = useNavigate();
  const { innerWidth } = window;

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
  }, []);

  const handleFormForgotPassword = async (e) => {
    e.preventDefault();
    try {
      setFormForgotPasswordAuthError(false);
      setFormForgotPasswordServerError(false);
    } catch (err) {
      setFormForgotPasswordServerError(true);
    }
  };

  return (
    <div className="app__forgot-container">
      <div className="app__forgot-sm-logo">
        <img src={images.logoSmallScreenFormForgotPassword} style={{ objectFit: 'contain', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }} alt="logo" />
      </div>
      <h3 className="forgot-text">Iniciar sesión</h3>
      <div className="app__forgot-form-container">
        <form className="form-forgot" onSubmit={handleFormForgotPassword}>
          <div className="app__forgot-input">
            <div className="app_forgot-input-svg">
              <FaUser />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          { forgotAuthError && <p key="1" className="form-p-error">Email o contraseña inválido</p>}
          { forgotServerError && <p key="1" className="form-p-error">Algo fue mal, intenta nuevamente</p>}
          <button className="signin-btn" type="submit">Iniciar sesión</button>
        </form>
        <a href="/forgotPassword" className="forgot-password">¿Olvidaste tu contraseña?</a>
      </div>

    </div>
  );
};

export default FormForgotPassword;
