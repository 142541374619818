import React, { useEffect, useState, useRef } from 'react';
import { images } from '../../constants';

import './MobileImgAboutSlider.scss';

const MobileImgAboutSlider = () => {
  const delay = 2500;

  const [imgIndex, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1)),
      delay,
    );

    return () => {
      resetTimeout();
    };
  }, [imgIndex]);

  return (
    <div className="app__about-slideshow">
      <div
        className="app__about-slideshowSlider"
        style={{ transform: `translate3d(${-imgIndex * 100}%, 0, 0)` }}
      >
        <div
          className="app__about-slide"
          key={1}
        >
          <div id="app__about-slide-item">
            <img src={images.aboutUsTrust} className="app__about-list-img" />
            <span>Transparencia</span>
          </div>
        </div>
        <div
          className="app__about-slide"
          key={2}
        >
          <div id="app__about-slide-item">
            <img src={images.aboutUsTractor} className="app__about-list-img" />
            <span>Confianza</span>
          </div>
        </div>
        <div
          className="app__about-slide"
          key={3}
        >
          <div id="app__about-slide-item">
            <img src={images.aboutUsConfidence} className="app__about-list-img" />
            <span>Respaldo</span>
          </div>
        </div>
      </div>

      <div className="app__about-slideshowDots">
        {[1, 2, 3].map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${imgIndex === idx ? ' active' : ''}`}
            onClick={() => {
              setIndex(idx);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileImgAboutSlider;
