import React from 'react';

import { AppWrap, MotionWrap } from '../../wrapper';
import { images } from '../../constants';
import { MobileImgAboutSlider } from '../../components';

import './About.scss';

const About = () => (
  <div className="app__about">
    <div className="app__about-info">
      <h2 className="head-text">
        ¿Quiénes somos?
      </h2>
      <div className="app__enterprise-intro-box">
        <p className="app__enterprise-intro-text">
          Somos una empresa familiar que opera en el mercado desde el año 2005, nos dedicamos a la venta de maquinaria agrícola nueva y usada, además vendemos repuestos e insumos para el agro.
        </p>
        <br />
        <span className="app__enterprise-intro-box-mision-vision">Mision</span>
        <p className="app__enterprise-intro-box-mision-vision">Proveer el mercado agropecuario con herramientas tecnológicas que aporten al desarrollo del sector agropecuario, incorporando tecnología para maximizar la eficiencia de nuestros clientes.</p>
        <br />
        <span className="app__enterprise-intro-box-mision-vision">Vision</span>
        <p className="app__enterprise-intro-box-mision-vision">Nuestro principal objetivo empresarial es ofrecer confianza y respaldo a cada uno de nuestros clientes.</p>
      </div>
      <MobileImgAboutSlider />
      <div className="app__enterprise-details">
        <div className="app__enterprise-value">
          <img src={images.aboutUsValuesTransparency} alt="enterprise-value" />
          <span>Transparencia</span>
        </div>
        <div className="app__enterprise-value" id="app__enterprise-vision">
          <img src={images.aboutUsValuesReliability} alt="enterprise-value" />
          <span>Confiabilidad</span>
        </div>
        <div className="app__enterprise-value">
          <img src={images.aboutUsValuesBackup} alt="enterprise-value" />
          <span>Respaldo</span>
        </div>
      </div>
    </div>
    <div className="app__about-img">
      <img src={images.aboutUsTractor} />
    </div>
  </div>
);

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'Nosotros',
  'app__about-us',
);
