/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';

import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Contact.scss';

const Contact = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    message: '',
    phone: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [nameErrors, setNameErrors] = useState([]);
  const [messageErrors, setMessageErrors] = useState([]);
  const [emailErrors, setEmailErrors] = useState([]);
  const [phoneErrors, setPhoneErrors] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);

  const { username, email, message, phone } = formData;
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validatePhone = (phoneToValidate) => String(phoneToValidate)
    .toLowerCase()
    .match(/^((09[1-9](\s?)([0-9]{3})(\s?)([0-9]{3}))|((2|4)(\s?)([0-9]{3})(\s?)([0-9]{2})(\s?)([0-9]{2})))$/g);
  const validateEmail = (emailToValidate) => String(emailToValidate)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const validateRequiredFields = () => {
    setNameErrors([]);
    setMessageErrors([]);
    setEmailErrors([]);
    setPhoneErrors([]);
    const nameErrorsAux = [];
    const messageErrorsAux = [];
    const emailErrorsAux = [];
    const phoneErrorsAux = [];
    if (username === '') {
      nameErrorsAux.push('El nombre es requerido');
    }
    if (username.length < 3 || username.length > 50) {
      nameErrorsAux.push('El nombre debe tener entre 3 y 50 caracteres');
    }
    if (message.length > 500) {
      messageErrorsAux.push('El mensaje no puede exceder los 500 caracteres');
    }
    if (!message) {
      messageErrorsAux.push('El mensaje es requerido');
    }
    if (validateEmail(email) === null) {
      emailErrorsAux.push('El email es inválido');
    }
    if (!email) {
      emailErrorsAux.push('El email es requerido');
    }
    if (validatePhone(phone) === null) {
      phoneErrorsAux.push('El teléfono es inválido');
    }
    setNameErrors(nameErrorsAux);
    setMessageErrors(messageErrorsAux);
    setEmailErrors(emailErrorsAux);
    setPhoneErrors(phoneErrorsAux);

    return (
      nameErrorsAux.length === 0
      && messageErrorsAux.length === 0
      && emailErrorsAux.length === 0
      && phoneErrorsAux.length === 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateRequiredFields()) {
      setLoading(true);
      setSendButtonDisabled(true);
      try {
        const contact = {
          name: formData.username,
          email: formData.email,
          message: formData.message,
          phone: formData.phone,
        };
        await axiosInstance.post('mail', {
          contact,
        });
        setIsFormSubmitted(true);
      } catch (error) {
        setErrorAlert(true);
      }
    }
  };

  const handleSendNewMessage = () => {
    setFormData({
      username,
      email,
      phone,
      message: '',
    });
    setIsFormSubmitted(false);
    setLoading(false);
    setSendButtonDisabled(false);
  };

  const handleAlertClose = (e) => {
    e.preventDefault();
    setErrorAlert(false);
    setLoading(false);
    setIsFormSubmitted(false);
    setSendButtonDisabled(false);
  };

  return (
    <div className="app__contact">
      <div className="app__contact-map-contact-container" style={{ paddingTop: `${!isFormSubmitted ? '2.5rem' : '13rem'}` }}>
        <div className="app__contact-map-div">
          <div className="google-map-code">
            <iframe
              width="648"
              height="463"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=ferraro%20maquinarias%20uruguy&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </div>
        </div>
        <div className="app__contact-div">
          <h2 id="app__contact-title" className="head-text" style={{ display: `${!isFormSubmitted ? 'block' : 'none'}` }}>
            Contactanos!
          </h2>
          { errorAlert && (
          <Alert onClose={(e) => handleAlertClose(e)} severity="error">
            Ups! Algo fue mal, intente nuevamente en un momento.
          </Alert>
          )}
          {!isFormSubmitted ? (
            <div className="app__contact-form app__flex">
              <div className="app__flex">
                <input
                  className="p-text"
                  type="text"
                  placeholder="Nombre"
                  name="username"
                  value={username}
                  onChange={handleChangeInput}
                />
              </div>
              {nameErrors
                && nameErrors.map((error, index) => (
                  <p key={index} className="form-p-error-contact">
                    {error}
                  </p>
                ))}
              <div className="app__flex">
                <input
                  className="p-text"
                  type="email"
                  placeholder="correo electrónico"
                  name="email"
                  value={email}
                  onChange={handleChangeInput}
                />
              </div>
              {emailErrors
                && emailErrors.map((error, index) => (
                  <p key={index} className="form-p-error-contact">
                    {error}
                  </p>
                ))}
              <div>
                <input
                  className="p-text"
                  type="phone"
                  placeholder="Teléfono"
                  name="phone"
                  value={phone}
                  onChange={handleChangeInput}
                />
              </div>
              {phoneErrors
                && phoneErrors.map((error, index) => (
                  <p key={index} className="form-p-error-contact">
                    {error}
                  </p>
                ))}
              <div>
                <textarea
                  className="p-text"
                  placeholder="Mensaje"
                  value={message}
                  name="message"
                  onChange={handleChangeInput}
                />
              </div>
              {messageErrors
                && messageErrors.map((error, index) => (
                  <p key={index} className="form-p-error-contact">
                    {error}
                  </p>
                ))}
              <button
                type="button"
                className="p-text"
                disabled={sendButtonDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                {!loading ? 'Enviar mensaje' : 'Enviando...'}
              </button>
            </div>
          ) : (
            <div className="div-container-success-message" style={window.innerWidth < 768 ? { top: '-2rem', position: 'relative' } : {}}>
              <h1>Gracias por contactarnos!</h1>
              <h2 className="h2-contact">En breves un representante se pondrá en contacto contigo</h2>
              <button
                type="button"
                className="new-contact-button"
                onClick={(e) => handleSendNewMessage(e)}
              >
                Quiero enviar otro mensaje
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="app__contact-footer">
        <div className="app__footer-logo">
          <img src={images.LogoFooter} alt="logo" />
        </div>
        <div className="copyright">
          <p className="p-text">
            © 2022 Ferraro - Todos los derechos reservados
          </p>
        </div>
        <div className="app__social">
          <div id="app__social-facebook">
            <a
              href=" https://es-la.facebook.com/Ferraromaquinariauy/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </div>
          <div id="app__social-instagram">
            <a
              href="https://www.instagram.com/ferraromaquinarias_/?hl=es"
              target="_blank"
              rel="noreferrer"
            >
              <BsInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Contact, 'app__contact'),
  'Contacto',
  'app__whitebg',
);
