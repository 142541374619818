/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import axios from 'axios';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import {
  WppFloatingButton,
  PhoneFloatingButton,
  Carousel,
} from '../../components';

import './ProductView.scss';
import { images } from '../../constants';

const ProductView = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const { id } = useParams();
  const [currentImage, setCurrentImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', inError: false });
  const counter = useRef(0);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const imageLoaded = (e) => {
    e.preventDefault();
    counter.current += 1;
    if (product && product.images && counter.current >= product.images.length) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError({ message: '', inError: false });
        const result = await axiosInstance.get(`products/${id}`);
        setProduct(result.data);
        setCurrentImage(result.data.images[0]);
      } catch (err) {
        setError({ message: 'Algo fue mal accediendo al producto, intente en un momento o comuníquese a nuestro whatsapp', inError: true });
      }
    };
    setTimeout(() => {
      fetchData();
    }, 3000);
  }, []);

  const onBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="app__product-view-container">
      <WppFloatingButton />
      <PhoneFloatingButton />
      <div className="app__productSearchBar">
        <div className="app__productSearchBar-logo">
          <a href="/">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__productSearchBar-sm-logo">
          <a href="/">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
      </div>
      <div className="app__product-view-data">
        <div className="app__product-view-data-breadcrumbs">
          <a href="/">Inicio</a><FaChevronRight /> <a href="/Tienda">Tienda</a> <FaChevronRight />{product.name}
        </div>
        { error && error.inError ? (
          <MessageBox variant="danger">{error.message}</MessageBox>
        ) : (

          <div className="app__product-view-data-inner-container">
            <div className="app__product-view-data-breadcrumbs-mobile" onClick={(e) => onBack(e)}> <FaChevronLeft /> Tienda</div>
            <div className="app__product-view-data-img">
              {loading && (
                <LoadingBox />
              )}
              <div className="app__product-view-data-img-current">
                <img
                  id="currentImage"
                  src={currentImage && `${process.env.REACT_APP_STATIC_FILES}${currentImage}`}
                  onLoad={(e) => imageLoaded(e)}
                  style={{ visibility: loading ? 'hidden' : 'visible' }}
                />
              </div>
              <div className="app__product-view-data-img-gallery">
                <div
                  className="app__products-view-carousel-desktop"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  {product && product.images && (
                  <Carousel
                    show={4}
                  >
                    {product.images.map((image) => (
                      <div style={{ cursor: 'pointer' }} key={image}>
                        <div
                          key={image}
                          style={{ padding: '0.5rem 0' }}
                          onClick={() => setCurrentImage(image)}
                        >
                          <img
                            key={image}
                            src={`${process.env.REACT_APP_STATIC_FILES}${image}`}
                            className="app__products-carousel-img"
                            onLoad={(e) => imageLoaded(e)}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                  )}
                </div>
                <div
                  className="app__products-carousel-mobile"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  {product && product.images && (
                  <Carousel show={3}>
                    {product.images.map((image) => (
                      <div style={{ cursor: 'pointer' }} key={image}>
                        <div
                          key={image}
                          style={{ padding: '0.5rem' }}
                          onClick={() => setCurrentImage(image)}
                        >
                          <img
                            key={image}
                            src={`${process.env.REACT_APP_STATIC_FILES}${image}`}
                            className="app__products-carousel-img"
                            onLoad={(e) => imageLoaded(e)}
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                  )}
                </div>
              </div>
            </div>
            {!loading && (
            <div className="app__product-view-data-info">
              <span className="app__product-view-data-info-name">
                {product.name}
              </span>
              <span className="app__product-view-data-info-brand">
                {product.brand}
              </span>
              <div className="app__product-view-data-info-description">
                {product.description}
              </div>
              <div className="app__product-view-data-info-state">
                Estado del producto: &nbsp;&nbsp;<b>{product.isNew ? 'Nuevo' : 'Usado'}</b>
              </div>
              <br />
              <div className="app__product-view-data-info-more">
                <div className="app__product-view-data-info-more-text">
                  ¿Estas interesado en este producto o uno similar?
                  <br />
                  Nuestros asesores pueden ayudarte
                </div>
                <button
                  type="button"
                  className="app__product-view-data-info-more-button"
                  onClick={() => { window.open('https://wa.me/59899541498'); }}
                  target="_blank"
                >
                  Mas información
                </button>
              </div>
            </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductView;
