import React from 'react';

import { FaTractor, FaTools, FaTrailer, FaChartBar } from 'react-icons/fa';
import { AppWrap, MotionWrap } from '../../wrapper';
// import { urlFor, client } from '../../client';
import { images } from '../../constants';
import { MobileImgServicesSlider } from '../../components';

import './Services.scss';

const Services = () => (
  <>
    <div className="app__services-bg-img-container">
      <img src={images.servicesTractorDesktop} className="app__services-bg-img" />
    </div>
    <div className="app__services-container">
      <div className="app__services-title">
        <h1 className="head-text">Servicios</h1>
        <h4 className="head-text">Todo lo que podemos ofrecerte</h4>
      </div>
      <MobileImgServicesSlider />
      <div className="app__services-list">
        <div className="app__services-list-item">
          <span>Tasación de maquinaria agrícola</span>
          <FaChartBar />
          <p>
            Vamos a su establecimiento sin compromiso para darle un valor estimado a sus equipos usados, colaboramos con la venta en caso de que el cliente lo solicite.
          </p>
        </div>
        <div className="app__services-list-item">
          <span>Maquinaria agrícola usada seleccionada</span>
          <FaTrailer />
          <p>
            Nos encargamos de buscarle en el mercado el implemento que necesita.
          </p>
        </div>
        <div className="app__services-list-item">
          <span>Maquinaria agrícola nueva</span>
          <FaTractor />
          <p>
            Trabajamos con los principales importadores del país, garantizando buen precio y respaldo.
          </p>
        </div>
        <div className="app__services-list-item">
          <span>Repuestos para el agro</span>
          <FaTools />
          <p>
            Ofrecemos una gama completa de repuestos, nos manejamos con stock propio en la mayoria de los casos.
          </p>
        </div>
      </div>
    </div>
  </>
);

export default AppWrap(
  MotionWrap(Services, 'app__services'),
  'Servicios',
  'app__whitebg',
);
