import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Navbar } from '../../components';

import './PageNotFound.scss';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="app__pageNotFound-container">
      <Navbar />
      <div className="app_pageNotFound-message-container">
        <span className="app__pageNotFound-message-code">404</span>
        <span className="app__pageNotFound-message-txt">Parece que la página que está buscando no existe.</span><br />
        <a href="/" onClick={navigate('/')}>Ir a página principal</a>
      </div>
    </div>
  );
};

export default PageNotFound;
