/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import AuthService from '../../../services/auth.service';
import { images } from '../../../constants';

import './FormBrand.scss';

const FormBrand = () => {
  const { state } = useLocation();
  const [formAction] = useState((state && state.formAction) || 'CREATE');
  const [name, setName] = useState((state && state.name) || '');
  const [nameErrors, setNameErrors] = useState([]);
  const [description, setDescription] = useState((state && state.description) || '');
  const [descriptionErrors, setDescriptionErrors] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successAlertContent, setSuccessAlertContent] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [id] = useState((state && state.id) || '');
  const [submitBtnText] = useState((state && state.submitBtnText) || 'Agregar');
  const { innerWidth } = window;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const navigate = useNavigate();

  const onCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateRequiredFields = () => {
    setNameErrors([]);
    setDescriptionErrors([]);
    const nameErrorsAux = [];
    const descriptionErrorsAux = [];
    if (name === '') {
      nameErrorsAux.push('El nombre es requerido');
    }
    if (name.length < 5 || name.length > 50) {
      nameErrorsAux.push('El nombre debe tener entre 5 y 50 caracteres');
    }
    if (description.length > 250) {
      descriptionErrorsAux.push('La descripción debe tener menos de 250 caracteres');
    }
    setNameErrors(nameErrorsAux);
    setDescriptionErrors(descriptionErrorsAux);
    return (nameErrorsAux.length === 0 && descriptionErrorsAux.length === 0);
  };

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
    if (!AuthService.getCurrentUser()) {
      navigate('/admin/login');
    }
    if (successAlert) {
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setSuccessAlert(false);
        navigate('/admin');
      }, 2000);
    }
  }, [successAlert]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorAlert(false);
    setSuccessAlert(false);
    const user = JSON.parse(localStorage.getItem('user'));

    if (validateRequiredFields()) {
      if (formAction === 'CREATE') {
        try {
          await axiosInstance.post('/brands',
            {
              name,
              description,
            },
            {
              headers: {
                'x-auth-token': user.accessToken,
              },
            });
          setSuccessAlert(true);
          setSuccessAlertContent('Marca creada con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      } else if (formAction === 'UPDATE') {
        try {
          await axiosInstance.put(`brands/${id}`,
            {
              name,
              description,
            },
            {
              headers: {
                'x-auth-token': user.accessToken,
              },
            });
          setSuccessAlert(true);
          setSuccessAlertContent('Marca actualizada con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      }
    }
  };

  return (
    <div className="app__form-brand-container">
      <div className="app__brand-header">
        <div className="app__brand-header-logo">
          <a href="/admin">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__brand-header-sm-logo">
          <a href="/admin">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
      </div>
      { successAlert
      && (
        <>
          <div className="app__form-alert-sucess" />
          <Alert>{successAlertContent}</Alert>
        </>
      )}
      <div className="app__form-brand-form-container">
        { errorAlert && (
        <Alert onClose={() => { setErrorAlert(false); }} severity="error">
          Ups! Algo fue mal, intente nuevamente en un momento.
        </Alert>
        )}
        <h3 className="form-brand-title">{(state && state.title) || 'Agregar nueva marca'}</h3>
        <br />
        <br />
        <form className="brand-form" onSubmit={(e) => onSubmit(e)}>
          <label>Nombre</label>
          <input
            className="form-brand-input"
            name="name"
            placeholder="Nombre de la marca"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={nameErrors && nameErrors.length > 0 ? { border: '1px solid red' } : {}}
          />
          { nameErrors
            && nameErrors.map((error, index) => (
              <p key={index} className="form-p-error">{error}</p>
            ))}
          <br />
          <br />
          <label>Descripción <label>(Opcional)</label></label>
          <textarea
            className="form-brand-textarea"
            name="description"
            placeholder="Descripción de la marca"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={descriptionErrors && descriptionErrors.length > 0 ? { border: '1px solid red' } : {}}
          />
          { descriptionErrors
            && descriptionErrors.map((error, index) => (
              <p key={index} className="form-p-error">{error}</p>
            ))}
          <br />
          <br />
          <div className="form-brand-btn-container">
            <button type="button" id="brand-cancel-btn" onClick={(e) => onCancel(e)}>Cancelar</button>
            <button type="button" id="brand-submbit-btn" onClick={(e) => onSubmit(e)}>{submitBtnText}</button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default FormBrand;
