/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import axios from 'axios';
import { FaChevronLeft, FaFilter } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
import { MdKeyboardArrowDown } from 'react-icons/md';
import LoadingBox from '../../components/LoadingBox';
import MessageBox from '../../components/MessageBox';
import './Store.scss';
import { images } from '../../constants';

const Checkbox = ({ label, value, onChange }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label>
    <input type="checkbox" checked={value} onChange={onChange} />
    {label}
  </label>
);

const Store = () => {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  // const [searchInputText] = React.useState(sp.get('text'));
  // TO DO - sp.get para cada filtro y agregar los valores como default state
  const [checkedNew, setcheckedNew] = React.useState(false);
  const [checkedUsed, setcheckedUsed] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [searchInputText, setSearchInputText] = React.useState(sp.get('text') || '');
  const [numberOfProducts, setNumberOfProducts] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageMobile, setCurrentPageMobile] = React.useState(0);
  const [toggle, setToggle] = useState(false);
  const [categoryChildDisplay, setCategoryChildDisplay] = useState('none');
  const [subCategoryChildDisplay, setSubCategoryChildDisplay] = useState('none');
  const [brandChildDisplay, setBrandChildDisplay] = useState('none');
  const [existMoreProducts, setExistMoreProducts] = useState(true);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);
  const counter = useRef(0);

  const { innerWidth } = window;
  const limit = 18;
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const navigate = useNavigate();
  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_REQUEST':
        return { ...state, loading: true };
      case 'FETCH_SUCCESS':
        if (innerWidth < 768 && action.payload && !action.payload.existSearchInputText) {
          // eslint-disable-next-line no-case-declarations
          const auxProducts = state.products || [];
          action.payload.map((product) => auxProducts.push(product));
          return { ...state, products: auxProducts, loading: false };
        } return { ...state, products: action.payload, loading: false };
      case 'FETCH_FAIL':
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: '',
  });

  const imageLoaded = (e) => {
    e.preventDefault();
    counter.current += 1;
    if (products && counter.current >= products.length) {
      setAllProductsLoaded(true);
    }
  };

  // handle scroll position after content load
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
      sessionStorage.removeItem('scrollPosition');
    }
  };

  // eslint-disable-next-line consistent-return
  const fetchData = async () => {
    let searchString = '';
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      const id = filter.id.split('_')[1];
      const typeOfFilter = filter.id.split('_')[0];
      switch (typeOfFilter) {
        case 'is':
          searchString += searchString
            ? `&is_new=${filter.value}`
            : `is_new=${filter.value}`;
          break;
        case 'brand':
          searchString += searchString ? `&brand_id=${id}` : `brand_id=${id}`;
          break;
        case 'subCategory':
          searchString += searchString
            ? `&sub_category_id=${id}`
            : `sub_category_id=${id}`;
          break;
        case 'category':
          searchString += searchString
            ? `&category_id=${id}`
            : `category_id=${id}`;
          break;
        default:
          return '';
      }
    }
    let isNewValue = null;
    if (!checkedNew && checkedUsed) {
      isNewValue = false;
    } else if (checkedNew && !checkedUsed) {
      isNewValue = true;
    }
    if (isNewValue !== null) {
      searchString += searchString
        ? `&is_new=${isNewValue}`
        : `is_new=${isNewValue}`;
    }
    let existSearchInputText = false;
    if (searchInputText) {
      searchString += searchString
        ? `&name=${searchInputText}`
        : `name=${searchInputText}`;
      searchString += `&description=${searchInputText}`;
      existSearchInputText = true;
    }
    dispatch({ type: 'FETCH_REQUEST' });
    try {
      let offset = (currentPage - 1) * limit;
      if (innerWidth < 768) {
        offset = (currentPageMobile) * limit;
      }

      const searchCondition = searchString
        ? `?limit=${limit}&offset=${offset}&${searchString}`
        : `?limit=${limit}&offset=${offset}`;
      const response = await axiosInstance.get(
        `products${searchCondition}`,
      );
      const { result, totalRegistries } = response.data;
      const pages = (totalRegistries + 1) / limit > 1
        ? Math.ceil(totalRegistries / limit) + 1
        : 1;
      setNumberOfProducts(Array.from(Array(pages).keys()).slice(1));
      if (innerWidth < 768) {
        result.existSearchInputText = existSearchInputText;
      }
      // eslint-disable-next-line no-unused-expressions
      dispatch({ type: 'FETCH_SUCCESS', payload: result });
      handleScrollPosition();

      if (totalRegistries === (products.length + result.length)) setExistMoreProducts(false);
    } catch (err) {
      dispatch({ type: 'FETCH_FAIL', payload: err.message });
    }
  };

  const fetchFilters = async () => {
    try {
      const { data: fetchedCategories } = await axiosInstance.get(
        'categories',
      );
      const { data: fetchedSubCategories } = await axiosInstance.get(
        'subCategories',
      );
      const { data: fetchedBrands } = await axiosInstance.get(
        'brands',
      );

      setCategories(fetchedCategories);
      setSubCategories(fetchedSubCategories);
      setBrands(fetchedBrands);
    } catch (err) {
      dispatch({ type: 'FETCH_FAIL', payload: err.message });
    }
  };

  const handleFilterClicked = (filterToAdd) => {
    if (filters && filters.length === 0) {
      setFilters([filterToAdd]);
    } else if (filters.find((filter) => filterToAdd.id === filter.id)) {
      setFilters(filters.filter((filter) => filter.id !== filterToAdd.id));
    } else {
      setFilters((existingFilters) => [filterToAdd, ...existingFilters]);
    }
  };

  const handleChangedNew = () => {
    setcheckedNew(!checkedNew);
  };

  const handleChangeUsed = () => {
    setcheckedUsed(!checkedUsed);
  };

  const handleChangeSearchInputText = (e) => {
    e.preventDefault();
    setSearchInputText(e.target.value);
  };

  const handleFetchStoreData = async () => {
    fetchData();
    navigate(`/Tienda?text=${searchInputText}`);
  };

  const handleSubmit = (e) => {
    // it triggers by pressing the enter key
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFetchStoreData();
    }
  };

  const handlePagination = (number) => {
    setCurrentPage(number);
  };

  const handleLoadMore = (e) => {
    e.preventDefault();
    setCurrentPageMobile(currentPageMobile + 1);
  };

  useEffect(() => {
    fetchData();
    fetchFilters();
  }, [currentPage, filters, checkedNew, checkedUsed, currentPageMobile]);

  const handleViewProduct = async (e) => {
    e.preventDefault();
    navigate(`/Tienda/producto/${e.target.id}`);
  };

  const handleFilterClickedMobile = async (e) => {
    e.preventDefault();
    const filterToAdd = {
      id: e.target.id,
      value: e.target.value,
    };

    if (filters && filters.length === 0) {
      setFilters([filterToAdd]);
    } else if (filters.find((filter) => filterToAdd.id === filter.id)) {
      setFilters(filters.filter((filter) => filter.id !== filterToAdd.id));
    } else {
      setFilters((existingFilters) => [filterToAdd, ...existingFilters]);
    }
  };

  const closeFilters = () => {
    setToggle(true);
    setCategoryChildDisplay('none');
    setSubCategoryChildDisplay('none');
    setBrandChildDisplay('none');
  };

  const handleCleanFilters = () => {
    setFilters([]);
    setcheckedNew(false);
    setcheckedUsed(false);
    setSearchInputText('');
    setCurrentPageMobile(1);
  };

  return (
    <>
      <div className="app__storeSearchBar">
        <div className="app__storeSearchBar-logo">
          <a href="/">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__storeSearchBar-sm-logo">
          <a href="/">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
        <div className="app__storeSearchBar-bar">
          <input
            type="search"
            value={searchInputText}
            onChange={handleChangeSearchInputText}
            onKeyDown={handleSubmit}
            placeholder="&#xf002;    Escribí el nombre de tu producto"
            className="app__products-search-bar-desktop"
          />
          <input
            type="search"
            value={searchInputText}
            onChange={handleChangeSearchInputText}
            onKeyDown={handleSubmit}
            placeholder="&#xf002; Buscar producto"
            className="app__products-search-bar-mobile"
          />
        </div>
        <div className="app__storeSearchBar-back" onClick={() => navigate(-1)}>
          <FaChevronLeft />
        </div>
      </div>
      {error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div className="app__store-container">
          {
              !allProductsLoaded && (
                <LoadingBox />
              )
          }
          <div className="app__store-products-filters-container">
            <div className="app__store-filters-mobile">
              <div className="app__store-navbar-filter">
                <FaFilter onClick={() => closeFilters()} />
                <p>Filtrar</p>
                {toggle && (
                  <motion.div
                    whileInView={{ x: [300, 0] }}
                    transition={{ duration: 0.5, ease: 'easeOut' }}
                    style={{ overflow: 'scroll' }}
                  >
                    <IoCloseOutline id="menu-cross" onClick={() => setToggle(false)} />
                    <ul>
                      <li key="checkbox-new">
                        <Checkbox
                          label="Nuevo"
                          value={checkedNew}
                          onChange={handleChangedNew}
                        />
                      </li>
                      <li key="checkbox-used">
                        <Checkbox
                          label="Usado"
                          value={checkedUsed}
                          onChange={handleChangeUsed}
                        />
                      </li>
                      <li key="filter-mobile-category" id="app__store-filters-mobile-li">
                        <div id="app__store-filters-mobile-categories-header" onClick={() => setCategoryChildDisplay(categoryChildDisplay === 'none' ? 'block' : 'none')}>
                          <span className="app__store-filter-title">Categorías</span>
                          <MdKeyboardArrowDown />
                        </div>
                        <div id="app__store-filter-entries" style={{ display: categoryChildDisplay }}>
                          {categories && categories.map((category) => (
                            <li key={category.id}>
                              <label>
                                {Array.isArray(filters)
                                  && filters.find(
                                    (filter) => filter.id === `category_${category.id}`,
                                  ) ? (
                                    <input
                                      type="checkbox"
                                      id={`category_${category.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                      checked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      id={`category_${category.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                    />
                                  )}
                                {category.name}
                              </label>
                            </li>
                          ))}
                        </div>
                      </li>
                      <li key="filter-mobile-subCategory" id="app__store-filters-mobile-li">
                        <div id="app__store-filters-mobile-subCategories-header" onClick={() => setSubCategoryChildDisplay(subCategoryChildDisplay === 'none' ? 'block' : 'none')}>
                          <span className="app__store-filter-title">Sub-Categorías</span>
                          <MdKeyboardArrowDown />
                        </div>
                        <div id="app__store-filter-entries" style={{ display: subCategoryChildDisplay }}>
                          {subCategories && subCategories.map((subCategory) => (
                            <li key={subCategory.id}>
                              <label>
                                {Array.isArray(filters)
                                  && filters.find(
                                    (filter) => filter.id === `subCategory_${subCategory.id}`,
                                  ) ? (
                                    <input
                                      type="checkbox"
                                      id={`subCategory_${subCategory.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                      checked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      id={`subCategory_${subCategory.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                    />
                                  )}
                                {subCategory.name}
                              </label>
                            </li>
                          ))}
                        </div>
                      </li>
                      <li key="filter-mobile-brand" id="app__store-filters-mobile-li">
                        <div id="app__store-filters-mobile-brands-header" onClick={() => setBrandChildDisplay(brandChildDisplay === 'none' ? 'block' : 'none')}>
                          <span className="app__store-filter-title">Marcas</span>
                          <MdKeyboardArrowDown />
                        </div>
                        <div id="app__store-filter-entries" style={{ display: brandChildDisplay }}>
                          {brands && brands.map((brand) => (
                            <li key={brand.id}>
                              <label>
                                {Array.isArray(filters)
                                  && filters.find(
                                    (filter) => filter.id === `brand_${brand.id}`,
                                  ) ? (
                                    <input
                                      type="checkbox"
                                      id={`brand_${brand.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                      checked
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      id={`brand_${brand.id}`}
                                      onClick={(e) => handleFilterClickedMobile(e)}
                                    />
                                  )}
                                {brand.name}
                              </label>
                            </li>
                          ))}
                        </div>
                      </li>
                    </ul>
                    <div id="app__store-filters-buttons">
                      <button type="button" id="app__store-filters-clear" onClick={(e) => handleCleanFilters(e)}>Limpiar filtros</button>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
            <div className="app__store-filters">
              <span className="app__store-span">Tienda</span>
              <div className="app__store-filter">
                <Checkbox
                  label="Nuevo"
                  value={checkedNew}
                  onChange={handleChangedNew}
                />
                <Checkbox
                  label="Usado"
                  value={checkedUsed}
                  onChange={handleChangeUsed}
                />
              </div>
              <div className="app__store-filter">
                {categories.length > 0 && (
                  <span className="app__store-filter-span">Categorias</span>
                )}
                {categories.length > 0 ? (
                  <ul>
                    {categories.map((category) => (
                      <li
                        className="app__store-filter-li"
                        key={`subCategory_${category.id}`}
                        value={category.name}
                        onClick={() => handleFilterClicked({
                          id: `category_${category.id}`,
                          value: category.name,
                        })}
                      >
                        {Array.isArray(filters)
                        && filters.find(
                          (filter) => filter.id === `category_${category.id}`,
                        ) ? (
                          <b>
                            <u>{category.name}</u>
                          </b>
                          ) : (
                            category.name
                          )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <br />
                )}
              </div>
              <div className="app__store-filter">
                {subCategories.length > 0 && (
                  <span className="app__store-filter-span">Sub categorias</span>
                )}
                {subCategories.length > 0 ? (
                  <ul>
                    {subCategories.map((subCategory) => (
                      <li
                        className="app__store-filter-li"
                        key={`subcategory_${subCategory.id}`}
                        value={subCategory.name}
                        onClick={() => handleFilterClicked({
                          id: `subCategory_${subCategory.id}`,
                          value: subCategory.name,
                        })}
                      >
                        {Array.isArray(filters)
                        && filters.find(
                          (filter) => filter.id === `subCategory_${subCategory.id}`,
                        ) ? (
                          <b>
                            <u>{subCategory.name}</u>
                          </b>
                          ) : (
                            subCategory.name
                          )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <br />
                )}
              </div>
              <div className="app__store-filter">
                {brands.length > 0 && (
                  <span className="app__store-filter-span">Marcas</span>
                )}
                {brands.length > 0 ? (
                  <ul>
                    {brands.map((brand) => (
                      <li
                        className="app__store-filter-li"
                        key={`brand_${brand.id}`}
                        value={brand.name}
                        onClick={() => handleFilterClicked({
                          id: `brand_${brand.id}`,
                          value: brand.name,
                        })}
                      >
                        {Array.isArray(filters)
                        && filters.find(
                          (filter) => filter.id === `brand_${brand.id}`,
                        ) ? (
                          <b>
                            <u>{brand.name}</u>
                          </b>
                          ) : (
                            brand.name
                          )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <br />
                )}
              </div>
            </div>
            <div className="app__store-products">
              {products && products.length > 0 ? (
                products.map((product) => (
                  <div
                    className="app__store-product-item"
                    key={product.id}
                    onClick={(e) => {
                      handleViewProduct(e);
                    }}
                  >
                    <div
                      id={product.id}
                      className="app__store-product-item-img"
                    >
                      <img
                        id={product.id}
                        src={`${process.env.REACT_APP_STATIC_FILES}${product.image}`}
                        onLoad={(e) => imageLoaded(e)}
                      />
                    </div>
                    <div
                      id={product.id}
                      className="app__store-product-item-info"
                    >
                      <span
                        id={product.id}
                        className="app__store-product-item-span"
                      >
                        {product.name}
                      </span>
                      <p id={product.id} className="app__store-product-item-p">
                        {product.description && product.description.length > 60 ? `${product.description.substring(0, 60)}...` : product.description}
                      </p>
                    </div>
                  </div>
                ))
              ) : loading && allProductsLoaded && (
              <h1 className="app__store-no-products">
                No se encontraron productos
              </h1>
              )}
            </div>
          </div>
          <div className="app__store-pagination">
            {numberOfProducts && numberOfProducts.length > 1 && numberOfProducts.map((number) => (
              <div
                className="app__store-pagination-item"
                style={
                  currentPage === number
                    ? { background: 'var(--green-color)', color: 'white' }
                    : { background: 'white', color: 'black' }
                }
                onClick={() => handlePagination(number)}
              >
                {number}
              </div>
            ))}
          </div>
          {innerWidth < 400 && existMoreProducts && allProductsLoaded && (
            <div className="app__store-load-more-btn-container">
              <button type="button" className="app__store-load-more-btn" onClick={(e) => handleLoadMore(e)}>Cargar más productos</button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Store;
