/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';

const NavigationDots = ({ active }) => (
  <div className="app__navigation">
    <div className="app__navigation-flexContainer">
      {['Inicio', 'Nosotros', 'Marcas', 'Productos', 'Servicios', 'Clientes', 'Contacto'].map(
        (item, index) => (
          <a
            href={`#${item}`}
            key={item + index}
            className="app__navigation-dot"
            style={active === item ? { backgroundColor: 'var(--nav-bar-color)' } : {}}
          />
        ),
      )}
    </div>
  </div>
);
export default NavigationDots;
