import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { AppWrap, MotionWrap } from '../../wrapper';
import { Carousel } from '../../components';

import './Products.scss';

const Product = () => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [products, setProducts] = useState([]);

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleShowAllClick = (e) => {
    e.preventDefault();
    navigate('/Tienda');
  };

  const handleChangeSearchString = (e) => {
    e.preventDefault();
    setSearchString(e.target.value);
  };

  const handleFetchStoreData = (e) => {
    e.preventDefault();
    navigate(`/Tienda?text=${searchString}`);
  };

  const handleSubmit = (e) => {
    // it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleFetchStoreData();
    }
  };

  useEffect(() => {
    const innerFunction = async () => {
      const response = await axiosInstance.get('products?limit=6');
      const { result } = response.data;
      setProducts(result);
    };
    innerFunction();
  }, []);

  const handleViewProduct = (e) => {
    e.preventDefault();
    navigate(`/Tienda/producto/${e.target.id}`);
  };

  return (
    <>
      <div className="app__products">
        <div className="app__products-title">
          <h1 className="head-text">Descubrí nuestros productos</h1>
          <h4 className="head-text">Te acompañamos en todas las etapas de la producción</h4>
        </div>
        <div className="app__products-search-container">
          <form>
            <input type="search" value={searchString} onChange={handleChangeSearchString} onKeyPress={handleSubmit} placeholder="&#xf002;   Escribí el nombre de tu producto" className="app__products-search-bar" />
            <div className="app__products-search-buttons-container">
              <button className="app__products-search-button" type="submit" onClick={handleFetchStoreData}>Buscar</button>
              <button className="app__products-search-button" id="app__products-search-show-all-btn" type="button" onClick={handleShowAllClick}>Ver todos</button>
            </div>
          </form>
        </div>
        <div className="app__products-list">
          <div className="app__products-carousel-desktop" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Carousel
              show={3}
            >
              {products && products.map((product) => (
                <div key={product.id} onClick={(e) => { handleViewProduct(e); }}>
                  <div id={product.id} style={{ padding: '0.5rem', borderRadius: '10px' }}>
                    <img id={product.id} src={`${process.env.REACT_APP_STATIC_FILES}${product.image}`} alt="placeholder" className="app__products-carousel-img" />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div id="app__products-carousel-mobile" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Carousel
              show={1}
            >
              {products && products.map((product) => (
                <div key={product.id} onClick={(e) => { handleViewProduct(e); }}>
                  <div id={product.id} style={{ padding: '0', borderRadius: '10px' }}>
                    <img id={product.id} src={`${process.env.REACT_APP_STATIC_FILES}${product.image}`} alt="placeholder" className="app__products-carousel-img" />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Product, 'app__products'),
  'Productos',
  'app__products-main',
);
