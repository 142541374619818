import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LandingPage, Store } from './container';

import './App.scss';
import Admin from './container/Admin/Admin';
import Login from './container/Login/Login';
import PageNotFound from './container/PageNotFound/PageNotFound';
import { FormCategory, FormBrand, FormProduct, FormSubCategory, FormForgotPassword } from './container/Forms';
import ProductView from './container/ProductView/ProductView';

const App = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/Tienda" element={<Store />} />
        <Route exact path="/admin" element={<Admin />} />
        <Route exact path="/Tienda/producto/:id" element={<ProductView />} />
        <Route exact path="/admin/login" element={<Login />} />
        <Route exact path="/admin/forgotPassword" element={<FormForgotPassword />} />
        <Route exact path="/admin/agregarCategoria" element={<FormCategory />} />
        <Route exact path="/admin/agregarMarca" element={<FormBrand />} />
        <Route exact path="/admin/agregarSubCategoria" element={<FormSubCategory />} />
        <Route exact path="/admin/agregarProducto" element={<FormProduct />} />
        <Route exact path="/admin/editarCategoria/:id" element={<FormCategory />} />
        <Route exact path="/admin/editarMarca/:id" element={<FormBrand />} />
        <Route exact path="/admin/editarSubCategoria/:id" element={<FormSubCategory />} />
        <Route exact path="/admin/editarProducto/:id" element={<FormProduct />} />
        <Route exact path="/pageNotFound" element={<PageNotFound />} />
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  </div>
);

export default App;
