/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../../../services/auth.service';

import { images } from '../../../constants';

import './FormProduct.scss';

const FormProduct = () => {
  const { state } = useLocation();
  const [formAction] = useState((state && state.formAction) || 'CREATE');
  const [name, setName] = useState((state && state.name) || '');
  const [nameErrors, setNameErrors] = useState([]);
  const [description, setDescription] = useState(
    (state && state.description) || '',
  );
  const [descriptionErrors, setDescriptionErrors] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successAlertContent, setSuccessAlertContent] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [id] = useState((state && state.id) || '');
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState(
    (state && state.category) || '',
  );
  const [categoryErrors, setCategoryErrors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandSelected, setBrandSelected] = useState(
    (state && state.brand) || '',
  );
  const [brandErrors, setBrandErrors] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState(
    (state && state.subCategory) || '',
  );
  const [submitBtnText] = useState((state && state.submitBtnText) || 'Agregar');
  const [disabledSubCategoriesSelect, setDisabledSubCategoriesSelect] = useState(true);
  const [disabledCategoriesSelect, setDisabledCategoriesSelect] = useState(true);
  const [disabledBrandsSelect, setDisabledBrandsSelect] = useState(true);
  const [productImages, setProductImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [productImagesErrors, setProductImagesErrors] = useState([]);
  const [productCondition, setProductCondition] = useState(
    (state && state.isNew) || '',
  );
  const [productConditionErrors, setProductConditionErrors] = useState([]);
  const { innerWidth } = window;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const navigate = useNavigate();

  const onCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleSetProductImages = (e) => {
    e.preventDefault();
    setProductImagesErrors([]);
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const [, value] of Object.entries(e.target.files)) {
      setProductImages((currentProductImages) => [...currentProductImages, value]);
    }
  };

  const validateRequiredFields = () => {
    setNameErrors([]);
    setDescriptionErrors([]);
    setCategoryErrors([]);
    setBrandErrors([]);
    setProductConditionErrors([]);
    setProductImagesErrors([]);
    const nameErrorsAux = [];
    const descriptionErrorsAux = [];
    const categoryErrorsAux = [];
    const brandErrorsAux = [];
    const productConditionErrorsAux = [];
    const productImagesErrorsAux = [];
    if (name === '') {
      nameErrorsAux.push('El nombre es requerido');
    }
    if (name.length < 5 || name.length > 50) {
      nameErrorsAux.push('El nombre debe tener entre 5 y 50 caracteres');
    }
    if (description.length > 400) {
      descriptionErrorsAux.push(
        'La descripción debe tener menos de 400 caracteres',
      );
    }
    if (!categorySelected) {
      categoryErrorsAux.push('La categoría es requerida');
    }
    if (!brandSelected) {
      brandErrorsAux.push('La marca es requerida');
    }
    if (!productCondition) {
      productConditionErrorsAux.push('La condición del producto es requerida');
    }
    if (formAction === 'CREATE' && (!productImages || productImages.length === 0)) {
      productImagesErrorsAux.push('Al menos una imágen nueva es requerida');
    }
    setNameErrors(nameErrorsAux);
    setDescriptionErrors(descriptionErrorsAux);
    setCategoryErrors(categoryErrorsAux);
    setBrandErrors(brandErrorsAux);
    setProductConditionErrors(productConditionErrorsAux);
    setProductImagesErrors(productImagesErrorsAux);
    return (
      nameErrorsAux.length === 0
      && descriptionErrorsAux.length === 0
      && categoryErrorsAux.length === 0
      && brandErrorsAux.length === 0
      && productConditionErrorsAux.length === 0
      && productImagesErrorsAux.length === 0
    );
  };

  useEffect(() => {
    const innerFunction = async () => {
      const { data: fetchedCategories } = await axiosInstance.get(
        'categories',
      );
      setCategories(fetchedCategories);

      const { data: fetchedBrands } = await axiosInstance.get(
        'brands',
      );
      setBrands(fetchedBrands);
      if (formAction === 'UPDATE') {
        const { data: productImagesFetched } = await axiosInstance.get(
          `products/imagesById/${id}`,
        );
        setExistingImages(productImagesFetched);
      }
    };
    innerFunction();
  }, []);

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
    if (!AuthService.getCurrentUser()) {
      navigate('/admin/login');
    }
    if (successAlert) {
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setSuccessAlert(false);
        navigate('/admin');
      }, 2000);
    }
  }, [successAlert]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorAlert(false);
    setSuccessAlert(false);
    const user = JSON.parse(localStorage.getItem('user'));
    if (validateRequiredFields()) {
      const formData = new FormData();

      formData.append('name', name);
      formData.append('description', description);
      formData.append('categoryId', categorySelected);
      formData.append('brandId', brandSelected);
      formData.append('subCategoryId', subCategorySelected);
      formData.append('isNew', productCondition === 'new');
      formData.append('existingImages', existingImages);
      Array.from(productImages).forEach((item) => {
        formData.append('images', item);
      });
      const url = 'products';
      if (formAction === 'CREATE') {
        try {
          await axiosInstance.post(url, formData, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          setSuccessAlert(true);
          setSuccessAlertContent('Producto creado con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      } else if (formAction === 'UPDATE') {
        try {
          await axiosInstance.put(`${url}/${id}`, formData, {
            headers: {
              'x-auth-token': user.accessToken,
            },
          });
          setSuccessAlert(true);
          setSuccessAlertContent('Producto actualizado con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      }
    }
  };

  const handleCategorySelected = (e) => {
    e.preventDefault();
    setCategoryErrors([]);
    setCategorySelected(e.target.value);
    // eslint-disable-next-line array-callback-return, consistent-return
    const subCategoriesFiltered = categories.find((category) => {
      if (
        category.id === Number(e.target.value)
        && category.SubCategories.length > 0
      ) {
        return category;
      }
    });
    if (subCategoriesFiltered) {
      setSubCategories(subCategoriesFiltered.SubCategories);
    } else {
      setSubCategories([]);
    }
  };

  useEffect(() => {
    if (subCategories.length > 0) {
      setDisabledSubCategoriesSelect(false);
    } else if (subCategorySelected !== '') {
      setDisabledSubCategoriesSelect(false);
      // eslint-disable-next-line array-callback-return, consistent-return
      const subCategoriesFiltered = categories.find((category) => {
        if (
          category.id === Number(categorySelected)
          && category.SubCategories.length > 0
        ) {
          return category;
        }
      });
      if (subCategoriesFiltered) {
        setSubCategories(subCategoriesFiltered.SubCategories);
      } else {
        setSubCategories([]);
      }
    } else {
      setDisabledSubCategoriesSelect(true);
    }
  }, [subCategories, subCategorySelected]);

  useEffect(() => {
    if (categories.length > 0) {
      setDisabledCategoriesSelect(false);
    } else {
      setDisabledCategoriesSelect(true);
    }
  }, [categories]);

  useEffect(() => {
    if (brands.length > 0 || brandSelected !== '') {
      setDisabledBrandsSelect(false);
    } else {
      setDisabledBrandsSelect(true);
    }
  }, [brands, brandSelected]);

  const handleBrandSelected = (e) => {
    e.preventDefault();
    setBrandErrors([]);
    setBrandSelected(e.target.value);
  };

  const handleSubCategorySelected = (e) => {
    e.preventDefault();
    setSubCategorySelected(e.target.value);
  };

  const handleRadioButtonChange = (e) => {
    setProductConditionErrors([]);
    setProductCondition(e.target.value);
  };

  const handleRemoveExistingImage = (e) => {
    e.preventDefault();
    const imageId = e.target.id;
    const newExistingImages = existingImages.filter(
      (image) => image !== imageId,
    );
    setExistingImages(newExistingImages);
  };

  const handleRemoveNewImage = (e) => {
    e.preventDefault();
    const imageName = e.target.id;
    const newProductImages = productImages.filter(
      (image) => image.name !== imageName,
    );
    setProductImages(newProductImages);
  };

  return (
    <div className="app__form-product-container">
      <div className="app__product-header">
        <div className="app__product-header-logo">
          <a href="/admin">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__product-header-sm-logo">
          <a href="/admin">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
      </div>
      { successAlert
      && (
        <>
          <div className="app__form-alert-sucess" />
          <Alert>{successAlertContent}</Alert>
        </>
      )}
      <div className="app__form-product-form-container">
        {errorAlert && (
          <Alert
            onClose={() => {
              setErrorAlert(false);
            }}
            severity="error"
          >
            Ups! Algo fue mal, intente nuevamente en un momento.
          </Alert>
        )}
        <h3 className="form-product-title">
          {(state && state.title) || 'Agregar nueva un producto'}
        </h3>
        <br />
        <form className="product-form" onSubmit={(e) => onSubmit(e)}>
          <label>Nombre</label>
          <input
            className="form-product-input"
            name="name"
            placeholder="Nombre del producto"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={
              nameErrors && nameErrors.length > 0
                ? { border: '1px solid red' }
                : {}
            }
          />
          {nameErrors
            && nameErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <label>
            Descripción <label>(Opcional)</label>
          </label>
          <textarea
            className="form-product-textarea"
            name="description"
            placeholder="Descripción del producto"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={
              descriptionErrors && descriptionErrors.length > 0
                ? { border: '1px solid red' }
                : {}
            }
          />
          {descriptionErrors
            && descriptionErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <label>Condición del producto</label>
          <div className="form-chk-product-state">
            <div className="form-chk-product-state-input">
              <input
                type="radio"
                key="new"
                id="new"
                name="nuevo"
                value="new"
                onChange={(e) => handleRadioButtonChange(e)}
                checked={productCondition === 'new'}
              />
              <label htmlFor="new">Nuevo</label>
            </div>
            <div className="form-chk-product-state-input">
              <input
                type="radio"
                key="used"
                id="used"
                name="usado"
                value="used"
                onChange={(e) => handleRadioButtonChange(e)}
                checked={productCondition === 'used'}
              />
              <label htmlFor="new">Usado</label>
            </div>
          </div>
          {productConditionErrors
            && productConditionErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <label>Categoría</label>
          <select
            className="form-product-select"
            disabled={disabledCategoriesSelect}
            onChange={(e) => handleCategorySelected(e)}
            value={categorySelected}
          >
            <option value="">Seleccione una categoría</option>
            {categories
              && categories.map((category) => (
                <option
                  key={category.id}
                  value={category.id}
                >
                  {category.name}
                </option>
              ))}
          </select>
          {categoryErrors
            && categoryErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <label>Sub-categoría</label>
          <select
            className="form-product-select"
            disabled={disabledSubCategoriesSelect}
            onChange={(e) => handleSubCategorySelected(e)}
            value={subCategorySelected}
          >
            <option value="">Seleccione una sub categoría</option>
            {subCategories
              && subCategories.map((subCategory) => (
                <option
                  key={subCategory.id}
                  value={subCategory.id}
                >
                  {subCategory.name}
                </option>
              ))}
          </select>
          <br />
          <label>Marca</label>
          <select
            className="form-product-select"
            disabled={disabledBrandsSelect}
            onChange={(e) => handleBrandSelected(e)}
            value={brandSelected}
          >
            <option value="">Seleccione una marca</option>
            {brands
              && brands.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
          </select>
          {brandErrors
            && brandErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <div className="img-preview-container">
            {
              Array.from(existingImages).map((item) => (
                <span key={item}>
                  <div id={item}><span id={item} onClick={(e) => handleRemoveExistingImage(e)} className="form-product-cross-span">x</span></div>
                  <img src={item ? `${process.env.REACT_APP_STATIC_FILES}${item}` : null} />
                </span>
              ))
            }
            {Array.from(productImages).map((item) => (
              <span key={item.name}>
                <div id={item.name}><span id={item} onClick={(e) => handleRemoveNewImage(e)} className="form-product-cross-span">x</span></div>
                <img src={item ? URL.createObjectURL(item) : null} />
              </span>
            ))}
          </div>
          <br />
          <label>Imagenes</label>
          <input
            onChange={(e) => {
              handleSetProductImages(e);
            }}
            multiple
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            className="form-product-input-images"
          />
          {productImagesErrors
            && productImagesErrors.map((error, index) => (
              <p key={index} className="form-p-error">
                {error}
              </p>
            ))}
          <br />
          <div className="form-product-btn-container">
            <button
              type="button"
              id="product-cancel-btn"
              onClick={(e) => onCancel(e)}
            >
              Cancelar
            </button>
            <button
              type="button"
              id="product-submbit-btn"
              onClick={(e) => onSubmit(e)}
            >
              {submitBtnText}
            </button>
          </div>
          <br />
          <br />
        </form>
      </div>
    </div>
  );
};

export default FormProduct;
