import React from 'react';

import './WppFloatingButton.scss';

const WppFloatingButton = () => (
  <a
    href="https://wa.me/59899541498"
    target="_blank"
    className="whatsapp_float"
    rel="noopener noreferrer"
  >
    <i className="fab fa-whatsapp whatsapp-icon" />
  </a>
);
export default WppFloatingButton;
