// Home page
import logoLargeScreen from '../assets/Logo-large-screen.png';
import logoSmallScreen from '../assets/Logos-03.png';
import logoSmallScreenLogin from '../assets/Logos-01.png';
import completeLogoHeader from '../assets/completeLogoHeader.svg';
import mobileHomeBackground from '../assets/mobile-home.png';
import desktopHomeBackground from '../assets/main.mp4';

// About us page
import aboutUsTractor from '../assets/About-us-backup.jpeg';
import aboutUsTractorMobile from '../assets/About-us-backup-mobile.svg';
import aboutUsTrust from '../assets/about-us-trust.jpeg';
import aboutUsConfidence from '../assets/about-us-confidence.jpeg';
import aboutUsValuesTransparency from '../assets/about-us-values-transparency.png';
import aboutUsValuesReliability from '../assets/about-us-values-reliability.png';
import aboutUsValuesBackup from '../assets/about-us-values-backup.png';

// Services
import servicesTractorDesktop from '../assets/Services-tractor-desktop.jpeg';
import servicesValuation from '../assets/valuation-mobile.jpeg';
import servicesOldTrucks from '../assets/old-truck-mobile.jpeg';
import servicesNewTrucks from '../assets/new-truck-mobile.jpeg';
import servicesTools from '../assets/tools-mobile.jpeg';

// Brands
import masseyFerguson from '../assets/brands/massey-ferguson-web.png';
import apache from '../assets/brands/apache-web.png';
import baldan from '../assets/brands/baldan-web.jpeg';
import krone from '../assets/brands/krone-web.png';
import tatuMarchesan from '../assets/brands/tatu-marchesan-web.png';
import dolbi from '../assets/brands/dolbi-web.png';
import shell from '../assets/brands/shell-web.png';
import caiman from '../assets/brands/caiman-web.webp';
import sitrex from '../assets/brands/sitrex-web.png';
import aguirre from '../assets/brands/aguirre.jpeg';
import bertini from '../assets/brands/bertini.png';
import cum from '../assets/brands/cum.png';
import inroda from '../assets/brands/inroda.png';
import jhartwich from '../assets/brands/jhartwich.png';
import ozdoken from '../assets/brands/ozdoken.png';
import richiger from '../assets/brands/richiger.png';
import vicon from '../assets/brands/vicon.png';

// Customers
import customer1 from '../assets/customers/customer1.jpeg';
import customer2 from '../assets/customers/customer2.jpeg';
import customer3 from '../assets/customers/customer3.jpeg';
import customer4 from '../assets/customers/customer4.jpeg';
import customer5 from '../assets/customers/customer5.jpeg';
import customer6 from '../assets/customers/customer6.jpeg';
import customer7 from '../assets/customers/customer7.jpeg';
import customer8 from '../assets/customers/customer8.jpeg';
import customer9 from '../assets/customers/customer9.jpeg';
import customer10 from '../assets/customers/customer10.jpeg';
import customer11 from '../assets/customers/customer11.jpeg';

// Footer
import LogoFooter from '../assets/Logo-footer.svg';

export default {
  customer1,
  customer2,
  customer3,
  customer4,
  customer5,
  customer6,
  customer7,
  customer8,
  customer9,
  customer10,
  customer11,
  completeLogoHeader,
  logoLargeScreen,
  logoSmallScreen,
  logoSmallScreenLogin,
  desktopHomeBackground,
  aboutUsTractor,
  LogoFooter,
  mobileHomeBackground,
  aboutUsValuesTransparency,
  aboutUsValuesReliability,
  aboutUsValuesBackup,
  aboutUsTractorMobile,
  servicesTractorDesktop,
  servicesValuation,
  servicesOldTrucks,
  servicesNewTrucks,
  servicesTools,
  masseyFerguson,
  apache,
  baldan,
  krone,
  tatuMarchesan,
  dolbi,
  shell,
  caiman,
  sitrex,
  aboutUsTrust,
  aboutUsConfidence,
  aguirre,
  bertini,
  cum,
  inroda,
  jhartwich,
  ozdoken,
  richiger,
  vicon,
};
