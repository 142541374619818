/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock } from 'react-icons/fa';
import AuthService from '../../services/auth.service';

import './Login.scss';
import { images } from '../../constants';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginAuthError, setLoginAuthError] = useState(false);
  const [loginServerError, setLoginServerError] = useState(false);
  const navigate = useNavigate();
  const { innerWidth } = window;

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
    if (AuthService.getCurrentUser()) {
      navigate('/admin');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoginAuthError(false);
      setLoginServerError(false);
      await AuthService.login(email, password).then(
        () => {
          navigate('/admin');
          window.location.reload();
        },
        () => {
          setLoginAuthError(true);
        },
      );
    } catch (err) {
      setLoginServerError(true);
    }
  };

  return (
    <div className="app__login-container">
      <div className="app__login-sm-logo">
        <img src={images.logoSmallScreenLogin} style={{ objectFit: 'contain', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }} alt="logo" />
      </div>
      <h3 className="login-text">Iniciar sesión</h3>
      <div className="app__login-form-container">
        <form className="form-login" onSubmit={handleLogin}>
          <div className="app__login-input">
            <div className="app_login-input-svg">
              <FaUser />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="app__login-input">
            <div className="app_login-input-svg">
              <FaLock />
            </div>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          { loginAuthError && <p key="1" className="form-p-error">Email o contraseña inválido</p>}
          { loginServerError && <p key="1" className="form-p-error">Algo fue mal, intenta nuevamente</p>}
          <button className="signin-btn" type="submit">Iniciar sesión</button>
        </form>
        <a href="/forgotPassword" className="forgot-password">¿Olvidaste tu contraseña?</a>
      </div>

    </div>
  );
};

export default Login;
