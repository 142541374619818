import React from 'react';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';

const Header = () => (
  <div className="app__header-container">
    <img className="app__header-container-mobile-img" src={images.mobileHomeBackground} />
    <div className="app__title__header">
      <div className="app__title__header-container">
        <img src={images.completeLogoHeader} />
        <h1>Incorporá tecnología y descubrí tu máximo potencial de producción</h1>
      </div>
    </div>
    <div className="video-container">
      <video autoPlay={window.innerWidth > 450} loop muted>
        <source src={images.desktopHomeBackground} type="video/mp4" />
      </video>
    </div>
    <div className="app__header-container-bg" />
  </div>
);

export default AppWrap(Header, 'Inicio', 'app__flex-inicio');
