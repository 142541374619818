import React, { useState } from 'react';
import { HiMenu, HiX } from 'react-icons/hi';
import { FaStore } from 'react-icons/fa';
import { motion } from 'framer-motion';

import { images } from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="app__navbar">
      <div className="app__navbar-logo">
        <a href="#Inicio">
          <img src={images.logoLargeScreen} alt="logo" />
        </a>
      </div>
      <div className="app__navbar-sm-logo">
        <a href="#Inicio" onClick={() => setToggle(false)}>
          <img src={images.logoSmallScreen} alt="logo" />
        </a>
      </div>
      <nav>
        <ul className="app__navbar-links">
          {['Inicio', 'Nosotros', 'Marcas', 'Productos', 'Servicios', 'Clientes', 'Contacto'].map(
            (item) => (
              <li className="app__flex p-text" key={`link-${item}`}>
                <div />
                <a href={`#${item}`}>{item}</a>
              </li>
            ),
          )}
          <li className="app__flex p-text" key="link-Tienda">
            <div />
            <a href="Tienda" id="app__navbar-link-store">
              <FaStore />
            </a>
          </li>
        </ul>

        <div className="app__navbar-menu">
          <HiMenu onClick={() => setToggle(true)} />

          {toggle && (
            <motion.div
              whileInView={{ x: [window.innerWidth - 100, 0] }}
              transition={{ duration: 0.95, ease: 'easeOut' }}
            >
              <HiX id="menu-cross" onClick={() => setToggle(false)} />
              <ul>
                {[
                  'Inicio',
                  'Nosotros',
                  'Marcas',
                  'Productos',
                  'Servicios',
                  'Clientes',
                  'Contacto',
                ].map((item) => (
                  <li id={`app__navbar-mobile-${item}`} key={item}>
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  </li>
                ))}
                <li id="app__navbar-mobile-Tienda" key="Tienda">
                  <a href="Tienda" onClick={() => setToggle(false)}>
                    Tienda
                  </a>
                </li>
              </ul>
            </motion.div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
