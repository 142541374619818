import React from 'react';
import About from '../About/About';
import Contact from '../Contact/Contact';
import Header from '../Header/Header';
import Services from '../Services/Services';
import Products from '../Products/Products';
import Brands from '../Brands/Brands';
import Customers from '../Customers/Customers';
import { Navbar, WppFloatingButton, PhoneFloatingButton } from '../../components';

const LandingPage = () => (
  <>
    <WppFloatingButton />
    <PhoneFloatingButton />
    <Navbar />
    <Header />
    <About />
    <Brands />
    <Products />
    <Services />
    <Customers />
    <Contact />
  </>
);

export default LandingPage;
