/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../../../services/auth.service';

import { images } from '../../../constants';

import './FormSubCategory.scss';

const FormSubCategory = () => {
  const { state } = useLocation();
  const [formAction] = useState((state && state.formAction) || 'CREATE');
  const [name, setName] = useState((state && state.name) || '');
  const [nameErrors, setNameErrors] = useState([]);
  const [description, setDescription] = useState((state && state.description) || '');
  const [descriptionErrors, setDescriptionErrors] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successAlertContent, setSuccessAlertContent] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [id] = useState((state && state.id) || '');
  const [categories, setCategories] = useState([]);
  const [categorySelected, setCategorySelected] = useState((state && state.categorySelected) || '');
  const [categoryErrors, setCategoryErrors] = useState([]);
  const [submitBtnText] = useState((state && state.submitBtnText) || 'Agregar');
  const { innerWidth } = window;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const navigate = useNavigate();

  const onCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateRequiredFields = () => {
    setNameErrors([]);
    setDescriptionErrors([]);
    setCategoryErrors([]);
    const nameErrorsAux = [];
    const descriptionErrorsAux = [];
    const categoryErrorsAux = [];
    if (name === '') {
      nameErrorsAux.push('El nombre es requerido');
    }
    if (name.length < 5 || name.length > 50) {
      nameErrorsAux.push('El nombre debe tener entre 5 y 50 caracteres');
    }
    if (description.length > 250) {
      descriptionErrorsAux.push('La descripción debe tener menos de 250 caracteres');
    }
    if (!categorySelected) {
      categoryErrorsAux.push('La categoría es requerida');
    }
    setNameErrors(nameErrorsAux);
    setDescriptionErrors(descriptionErrorsAux);
    setCategoryErrors(categoryErrorsAux);
    return (nameErrorsAux.length === 0 && descriptionErrorsAux.length === 0 && categoryErrorsAux.length === 0);
  };

  useEffect(() => {
    const innerFunction = async () => {
      const { data: fetchedCategories } = await axiosInstance.get(
        'categories',
      );
      setCategories(fetchedCategories);
    };
    innerFunction();
  }, []);

  useEffect(() => {
    if (innerWidth < 400) {
      navigate('/pageNotFound');
    }
    if (!AuthService.getCurrentUser()) {
      navigate('/admin/login');
    }
    if (successAlert) {
      setTimeout(() => {
        // After 3 seconds set the show value to false
        setSuccessAlert(false);
        navigate('/admin');
      }, 2000);
    }
  }, [successAlert]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorAlert(false);
    setSuccessAlert(false);
    const user = JSON.parse(localStorage.getItem('user'));
    if (validateRequiredFields()) {
      if (formAction === 'CREATE') {
        try {
          await axiosInstance.post('subCategories',
            {
              name,
              description,
              categoryId: categorySelected,
            },
            {
              headers: {
                'x-auth-token': user.accessToken,
              },
            });
          setSuccessAlert(true);
          setSuccessAlertContent('Sub categoría creada con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      } else if (formAction === 'UPDATE') {
        try {
          await axiosInstance.put(`subCategories/${id}`,
            {
              name,
              description,
              categoryId: categorySelected,
            },
            {
              headers: {
                'x-auth-token': user.accessToken,
              },
            });
          setSuccessAlert(true);
          setSuccessAlertContent('Sub categoría actualizada con éxito');
        } catch (error) {
          setErrorAlert(true);
        }
      }
    }
  };

  const handleCategorySelected = (e) => {
    e.preventDefault();
    setCategoryErrors([]);
    setCategorySelected(e.target.value);
  };

  return (
    <div className="app__form-subCategory-container">
      <div className="app__subCategory-header">
        <div className="app__subCategory-header-logo">
          <a href="/admin">
            <img src={images.logoLargeScreen} alt="logo" />
          </a>
        </div>
        <div className="app__subCategory-header-sm-logo">
          <a href="/admin">
            <img src={images.logoSmallScreen} alt="logo" />
          </a>
        </div>
      </div>
      { successAlert
      && (
        <>
          <div className="app__form-alert-sucess" />
          <Alert>{successAlertContent}</Alert>
        </>
      )}
      <div className="app__form-subCategory-form-container">
        { errorAlert && (
        <Alert onClose={() => { setErrorAlert(false); }} severity="error">
          Ups! Algo fue mal, intente nuevamente en un momento.
        </Alert>
        )}
        <h3 className="form-subCategory-title">{(state && state.title) || 'Agregar nueva sub categoría'}</h3>
        <br />
        <form className="subCategory-form" onSubmit={(e) => onSubmit(e)}>
          <label>Nombre</label>
          <input
            className="form-subCategory-input"
            name="name"
            placeholder="Nombre de la sub categoría"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={nameErrors && nameErrors.length > 0 ? { border: '1px solid red' } : {}}
          />
          { nameErrors
            && nameErrors.map((error, index) => (
              <p key={index} className="form-p-error">{error}</p>
            ))}
          <br />
          <label>Descripción <label>(Opcional)</label></label>
          <textarea
            className="form-subCategory-textarea"
            name="description"
            placeholder="Descripción de la sub categoría"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={descriptionErrors && descriptionErrors.length > 0 ? { border: '1px solid red' } : {}}
          />
          { descriptionErrors
            && descriptionErrors.map((error, index) => (
              <p key={index} className="form-p-error">{error}</p>
            ))}
          <br />
          <label>Categoría</label>
          <select className="form-subCategory-select" value={categorySelected} onChange={(e) => handleCategorySelected(e)}>
            <option value="">Seleccione una categoría</option>
            {categories && categories.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </select>
          { categoryErrors
            && categoryErrors.map((error, index) => (
              <p key={index} className="form-p-error">{error}</p>
            ))}
          <br />
          <div className="form-subCategory-btn-container">
            <button type="button" id="subCategory-cancel-btn" onClick={(e) => onCancel(e)}>Cancelar</button>
            <button type="button" id="subCategory-submbit-btn" onClick={(e) => onSubmit(e)}>{submitBtnText}</button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default FormSubCategory;
