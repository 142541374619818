import React from 'react';

import './PhoneFloatingButton.scss';

const PhoneFloatingButton = () => (
  <a
    href="tel:+598 99 541 498"
    className="phone_float"
    target="_blank"
    rel="noopener noreferrer"
  >
    <i className="fa fa-phone phone-icon" />
  </a>
);
export default PhoneFloatingButton;
